<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    props: ["statusRegister", "awardsData", "statusRange", "dataModels"],
    watch: {
      statusRange(newValue) {
        this.statusRange = newValue;
      },
      dataModels(newValue) {
        this.rangeLimit = newValue.length;
      },
    },
    data() {
      return {
        contestAwards: [],
        currentAward: {
          position: 1,
          name: "",
          logo: null,
          positionStart: 1,
          positionEnd: 1,
        },
        statusNewAwards: true,
        rangeLimit: 10,
      };
    },
    computed: {
      ...mapGetters("competitions", ["getCompetition"]),
    },

    methods: {
      ...mapActions("competitions", ["createCompetitionAward", "getCompetitionById"]),

      openCurrentInput(event) {
        event.preventDefault();
        this.$refs["afileInput_current"].click();
      },
      openFileInput(event, index) {
        event.preventDefault();
        this.$refs["afileInput" + index][0].click();
      },
      handleFileChange2() {
        const fileInput = this.$refs["afileInput_current"];
        const file = fileInput.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.currentAward.logo = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
      clearNewAwards() {
        const fileInput = this.$refs["afileInput_current"];
        fileInput.value = null;
        this.currentAward = {
          position: null,
          positionStart: this.contestAwards[this.contestAwards.length - 1]?.positionEnd + 1,
          name: "",
          logo: null,
        };
      },
      handleFileChange(index) {
        const fileInput = this.$refs["afileInput" + index];
        const file = fileInput[0].files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.contestAwards[index].logo = e.target.result;
            this.updateAwards(this.contestAwards[index]);
          };
          reader.readAsDataURL(file);
        }
      },
      cancelAwards(event) {
        event.preventDefault();
        this.clearNewAwards();
      },
      async updateAwards(awardItem) {
        if (awardItem.name && awardItem.logo) {
          const { contestUpdated } = await this.createCompetitionAward({ idContest: this.$route.params.id, action: "edit", formJson: awardItem });
          if (contestUpdated) {
            this.$toast.success("Actualizando");
          }
          this.contestAwards = contestUpdated?.awards;
        } else {
          this.$toast.error("Campo nombre y logo son requeridos");
        }
      },

      async updateAward(award) {
        const { _id, name, position, positionStart, positionEnd } = award;
        const range = this.statusRange;

        if (!name) {
          this.$toast.error("Campo nombre es requerido");
          return;
        }

        if (range && this.currentAward?.positionEnd && this.currentAward?.positionEnd < this.currentAward?.positionStart) {
          this.$toast.error("La posición final no puede ser menor que la posición inicial");
          return;
        }

        let jsonData = { name, position, _id };

        if (positionStart && range) {
          jsonData.positionStart = Number(positionStart);
        }
        if (positionEnd && range) {
          jsonData.positionEnd = Number(positionEnd);
        }

        const { contestUpdated } = await this.createCompetitionAward({ idContest: this.$route.params.id, action: "edit", formJson: jsonData });
        this.contestAwards = contestUpdated?.awards;
        if (contestUpdated) {
          this.$toast.success("Actualizando");
        }
      },

      getLastAwardPosition() {
        const totalAwards = this.contestAwards.length;
        const lastAward = this.contestAwards[totalAwards - 1];

        return lastAward ? lastAward.position : 0;
      },

      getAwardPosition() {
        return this.getLastAwardPosition() + 1 + new Date().getTime();
      },

      createAwardModel({ name, positionStart, positionEnd, logo }) {
        const range = this.statusRange;

        if (!name) {
          this.$toast.error("Campo nombre y logo son requeridos");
          return;
        }

        if (range && positionEnd && positionEnd < positionStart) {
          this.$toast.error("La posición final no puede ser menor que la posición inicial");
          return;
        }

        const awardModel = { name, position: this.getAwardPosition(), range };
        if (positionStart && range) {
          awardModel.positionStart = positionStart;
        }
        if (positionEnd) {
          awardModel.positionEnd = positionEnd;
        }
        if (logo) {
          awardModel.logo = logo;
        }

        return awardModel;
      },

      async saveAwards(event) {
        event.preventDefault();

        const awardModel = this.createAwardModel(this.currentAward);

        const { contestUpdated } = await this.createCompetitionAward({ idContest: this.$route.params.id, action: "create", formJson: awardModel });
        this.contestAwards = contestUpdated.awards;

        if (contestUpdated) {
          this.$toast.success("Premio creado");
        }
        this.clearNewAwards();
      },
      async deleteAward(_id, idx) {
        const { contestUpdated } = await this.createCompetitionAward({ idContest: this.$route.params.id, action: "delete", formJson: { _id } });

        if (contestUpdated) {
          this.contestAwards.splice(idx, 1);
          this.$toast.success("Premio eliminado");
        }
      },
      validateNewSave() {
        if (this.statusRange) {
          return !this.currentAward.name || !this.currentAward.positionEnd;
        } else {
          if (!this.currentAward.name) {
            return true;
          }
        }
      },
      validateRange(numero, position) {
        let tmpAw = this.contestAwards.map((it) => [it.positionStart, it.positionEnd]);

        for (let it of tmpAw) {
          const rango = it;
          const inicio = rango[0];
          const fin = rango[1];

          if (numero >= inicio && numero <= fin) {
            this.$toast.error("El valor ingresado ya se encuentra asignado");
            this.currentAward[position] = 0;
            return true;
          }
        }
        if (position === "positionEnd" && numero < this.currentAward.positionStart) {
          this.$toast.error("El valor ingresado es menor a la posición inicial");
          this.currentAward[position] = this.currentAward.positionStart + 1;
          return true;
        }
        if (position === "positionStart" && numero > this.currentAward.positionEnd) {
          this.currentAward.positionEnd = Number(numero) + 1;
          return true;
        }

        return false;
      },

      getCompetitionAwards() {
        const competitionAwards = this.getCompetition?.awards;

        if (competitionAwards) {
          this.contestAwards = competitionAwards;
        }
      },

      setUpAwardOptions() {
        if (this.contestAwards?.length) {
          this.rangeLimit = this.dataModels?.length;
          this.currentAward.positionStart = this.contestAwards[this.contestAwards.length - 1]?.positionEnd + 1;
          this.currentAward.positionEnd = this.contestAwards[this.contestAwards.length - 1]?.positionEnd + 2;
        }
      },
    },
    async mounted() {
      await this.getCompetitionById({ _id: this.$route.params.id });
      this.getCompetitionAwards();
      this.setUpAwardOptions();
    },
  };
</script>

<template>
  <div class="awards" v-if="!statusRegister">
    <div v-for="(item, index) in contestAwards" class="awards__content" :key="index">
      <div class="awards__content--position">
        <span>{{ index + 1 }}.</span>
      </div>
      <div class="awards__content--input">
        <input v-on:focusout="updateAward(contestAwards[index])" v-model="contestAwards[index].name" type="text" />
      </div>
      <div v-if="statusRange" class="awards__content--range">
        <div class="awards__content--range--content">
          <div class="inputs__range">
            <label class="inputs__range--label" for="start">Desde:</label>
            <select @change="updateAward(contestAwards[index])" v-model="item.positionStart" class="inputs__range--options" name="start">
              <option value="">Rango inicial</option>
              <option v-for="index in rangeLimit" :value="index" :key="index">{{ index }}</option>
            </select>
          </div>
          <div class="inputs__range">
            <label class="inputs__range--label" for="end">Hasta:</label>
            <select @change="updateAward(contestAwards[index])" v-model="item.positionEnd" class="inputs__range--options" name="end">
              <option value="">Rango inicial</option>
              <option v-for="index in rangeLimit" :value="index" :key="index">{{ index }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="awards__content--file">
        <div class="awards__content--file--img" @click="($event) => openFileInput($event, index)">
          <img v-if="item.logo" :src="item.logo" alt="Uploaded" class="awards__img--bg" />
          <span v-else>+</span>
        </div>
        <input :multiple="false" :ref="'afileInput' + index" type="file" hidden @change="() => handleFileChange(index)" />
      </div>
      <iconic name="trashPoll" class="awards__trash" @click.native="deleteAward(item._id, index)"></iconic>
    </div>

    <div v-if="statusNewAwards" class="awards__content">
      <div class="awards__content--position">
        <span>{{ contestAwards.length + 1 }}.</span>
      </div>
      <div class="awards__content--input">
        <input v-model="currentAward.name" type="text" v-on:keyup.enter="saveAwards" />
      </div>
      <div v-if="statusRange" class="awards__content--range">
        <div class="awards__content--range--content">
          <div class="inputs__range">
            <label class="inputs__range--label" for="start">Desde:</label>
            <select v-model="currentAward.positionStart" @change="() => validateRange(currentAward.positionStart, 'positionStart')" class="inputs__range--options">
              <option value="">Posición inicial</option>
              <option v-for="index in rangeLimit" :value="index" :key="index">{{ index }}</option>
            </select>
          </div>
          <div class="inputs__range">
            <label class="inputs__range--label" for="end">Hasta:</label>
            <select v-model="currentAward.positionEnd" @change="() => validateRange(currentAward.positionEnd, 'positionEnd')" class="inputs__range--options">
              <option value="">Posición final</option>
              <option v-for="index in rangeLimit" :value="index" :key="index">{{ index }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="awards__content--file file__current">
        <div class="awards__content--file--img" @click="($event) => openCurrentInput($event)">
          <img v-if="currentAward.logo" :src="currentAward.logo" alt="Uploaded" class="awards__img--bg" />
          <span v-else>+</span>
        </div>
        <input :multiple="false" :ref="'afileInput_current'" type="file" hidden @change="() => handleFileChange2()" />
      </div>
    </div>
    <div class="awards__btn--newContent">
      <button :disabled="validateNewSave()" class="awards__btn--new" @click="cancelAwards">Cancelar</button>
      <button :disabled="validateNewSave()" :class="validateNewSave() ? 'awards__btn--new' : 'awards__btn--save'" @click="saveAwards">Guardar</button>
    </div>
  </div>
  <div v-else>
    <div class="rooms__notRegister">
      <span class="rooms__notRegister_label">Ingrese el nombre del concurso para iniciar.</span>
    </div>
  </div>
</template>

<style lang="scss">
  .awards {
    .competitions__plus-disabled {
      padding: 0.5rem;
      margin-left: 10px;
      background: #efefef;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      transition: all 0.4s ease;
    }
    &__trash {
      width: 40px;
      height: 40px;
      padding: 10px;
      margin-top: 10px;
      color: $chicago;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        background-color: #efefef;
      }
    }
    &__notRegister {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 400px;
      color: $primary;
      font-weight: 800;
    }

    &__btn {
      &--newContent {
        display: flex;
        justify-content: space-between;
        border-radius: 12px;
      }

      &--new {
        padding: 5px 15px;
        margin-top: 10px;
        width: 200px;
        background-color: #efefef;
        border: #a8a9a9;
        margin-left: 15px;
        border-radius: 12px;
      }

      &--save {
        padding: 5px 15px;
        margin-top: 10px;
        width: 200px;
        border: $primary;
        background-color: $primary;
        color: #fff;
        margin-left: 15px;
        border-radius: 12px;
      }
    }

    &__content {
      width: 100%;
      padding: 10px 5px;
      display: flex;
      gap: 5px;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
      }

      &--img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;

        .competitions__plus {
          width: 35px;
          height: 35px;
        }
      }

      &--position {
        color: $primary;
        width: 5%;
        display: flex;
        align-items: flex-end;
        font-size: 20px;
        text-align: center;

        span {
          width: 100%;
        }
      }

      &--input {
        width: 100%;
        padding: 0 10px;
        display: flex;
        align-items: flex-end;

        input {
          width: 100%;
          height: 30px;
          border: none;
          border-bottom: 0.5px solid #a8a9a9;
        }
      }
      &--range {
        @media (max-width: 768px) {
          width: 100%;
          margin-top: 20px;
        }
        width: 250px;
        padding: 0 10px;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        span {
          color: $primary;
          font-weight: 800;
        }
        &--content {
          @media (max-width: 768px) {
            width: 100%;
          }
          width: 190px;
          .inputs__range {
            display: flex;
            align-items: flex-end;
            &--label {
              margin-right: 10px;
              width: 30%;
            }
            input,
            select {
              width: 70%;
              height: 30px;
              border: none;
              border-bottom: 0.5px solid #a8a9a9;
              text-align: end;
              background: transparent;
            }
            select {
              width: 70%;
              height: 30px;
              border: none;
              border-bottom: 0.5px solid #a8a9a9;
              text-align: end;
              background: transparent;
            }
          }
        }

        input {
          width: 100%;
          height: 30px;
          border: none;
          border-bottom: 0.5px solid #a8a9a9;
        }
      }

      &--file.file__current {
        padding: 0 40px 0 0;
      }
      &--file {
        width: auto;
        @media (max-width: 768px) {
          margin: auto;
        }
        &--img {
          @media (max-width: 768px) {
            margin-top: 20px;
            width: 120px;
            height: 120px;
          }
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          border-radius: 10px;
          background-color: #efefef;
          cursor: pointer;
          transition: all 0.4s ease;

          &:hover {
            transform: scale(1.2);
            background-color: #e6e6e6;
          }

          .awards__img--bg {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
      }
    }

    .toolbar-btn.toolbar-btn__resize {
      display: none;
    }
  }
</style>
