<script>
import UsersViewCompetition from "./UsersViewCompetition.vue";
import PolicyUsersByArea from "./PolicyUsersByArea.vue";
export default {
  props: ["users", "areas"],
  components: {
    UsersViewCompetition,
    PolicyUsersByArea,
  },
};
</script>

<template>
  <section class="competition__policyViewSections">
    <div class="competition__policyViewSectionSelect">
      <template v-if="users.length">
        <PolicyUsersByArea :users="users" :areas="areas" />
      </template>
      <div v-else class="competition__notFound">
        <p class="competition__notFoundText">No se encontró usuario</p>
      </div>
    </div>
    <UsersViewCompetition />
    <p class="competition__policiesCompetitionsWarning">
      <span class="competition__policiesCompetitionsWarningI">*</span>
      Solo podrán comentar usuarios que esten seleccionados en la lista de visualización.
    </p>
  </section>
</template>

<style lang="scss">
.competition {
  &__policyViewSections {
    height: 95%;
  }

  &__policiesCompetitionsWarning {
    position: absolute;
    bottom: 0.5rem;
    left: 1rem;
    right: 1rem;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    color: rgba(50, 51, 59, 1);
    background: #fff;
  }
  &__policiesCompetitionsWarningI {
    font-size: 15px;
    font-weight: bold;
    color: $primary;
  }

  @media (min-width: 769px) {
    &__policyViewSections {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    &__policyViewSectionSelect {
      border-right: 1px solid rgba(221, 221, 221, 1);
    }
    &__policyViewFilters {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
