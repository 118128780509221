<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters("competitions", [
      "getCompetition",
      "getUsersSelecetedView",
      "getInteractivePolicy",
      "getUsersSelectInteractive",
      "getUsersSelectedInteractive",
    ]),

    users() {
      return this.getInteractivePolicy ? this.getUsersSelectedInteractive : this.getUsersSelecetedView;
    },

    title() {
      return this.getInteractivePolicy ? "Personas con permiso para comentar en el concurso." : "Personas con permiso para ver el concurso.";
    },
  },
  methods: {
    ...mapActions("competitions", ["updateUsersViewCompetition"]),
    ...mapMutations("competitions", ["setUsersSelecetedView", "setUsersSelectedInteractive"]),

    nameUser({ artisticName, user }) {
      return artisticName || user;
    },

    nameArea({ area }) {
      return area?.name;
    },

    async deleteUser(user) {
      const contestId = this.getCompetition._id;
      let policies = "policiesView";

      if (this.getInteractivePolicy) {
        policies = "policiesInteration";
        this.setUsersSelectedInteractive(user);
      } else {
        const isIncluded = this.getUsersSelectedInteractive.some((u) => u._id === user._id);
        if (isIncluded) {
          this.setUsersSelectedInteractive(user);
        }

        this.setUsersSelecetedView(user);
      }

      await this.updateUsersViewCompetition({ contestId, action: "editUsers", userId: user._id, police: "users", policies });
      this.$toast.success("Actualizado");

    },
  },
};
</script>

<template>
  <div class="competition__usersAllow">
    <p class="competition__usersAllowTitle">{{ title }}</p>
    <div v-if="users.length" class="competition__usersAllowC custom__scroll">
      <div @click="deleteUser(user)" v-for="user in users" :key="user._id" class="competition__usersAllowContainer">
        <div class="competition__usersAllowProfile">
          <Avatar class="competition__userImg" :user="user" />
          <span class="competition__userCheckName">{{ nameUser(user) }}</span>
        </div>
        <div class="competition__userCheckArea">
          <span class="competition__userCheckAreaName">{{ nameArea(user) }}</span>
          <iconic class="competition__iconAllow" name="close" />
        </div>
      </div>
    </div>
    <div v-else class="competition__notFound">
      <p class="competition__notFoundText">Aun no se han asignado usuarios</p>
    </div>
  </div>
</template>

<style lang="scss">
.competition {
  &__usersAllow {
    margin: 0.5rem 0;
    line-height: 17px;
    font-weight: 400;
    font-size: 14px;
  }
  &__usersAllowC {
    max-height: 370px;
    overflow-y: auto;
  }
  &__usersAllowTitle {
    height: 2.8rem;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(221, 221, 221, 1);
    border-bottom: 1px solid rgba(221, 221, 221, 1);
  }
  &__usersAllowContainer {
    @include Flex(row, space-between);
    margin: 0.5rem 0;
    padding: 0.5rem 0.7rem;
    background: rgba(250, 250, 250, 1);
    border-radius: 10px;
  }
  &__usersAllowProfile {
    @include Flex(row, flex-start);
    gap: 10px;
  }
  &__userCheckAreaName {
    display: inline-block;
    text-align: center;
    width: 7rem;
    padding: 0.2rem;
    text-transform: capitalize;
    border-radius: 1rem;
    background: rgba(238, 238, 238, 1);
  }
  &__iconAllow {
    cursor: pointer;
    margin-left: 10px;
    font-size: 10px;
  }
  &__notFound {
    margin-top: 2rem;
    text-align: center;
  }
  &__notFoundText {
    font-weight: bold;
    color: $primary;
  }

  @media (min-width: 769px) {
    &__usersAllowTitle {
      border-top: 0;
    }
  }
}
</style>
