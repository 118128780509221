<script>
  export default {
    props: {
      headers: Array,
      data: Array,
      modal: Boolean,
      competition: {
        type: Object,
        default: {},
      },
    },
    data() {
      return {
        indexInDrag: null,
        isLoadingUpdate: false,
      };
    },
    computed: {
      canDrag() {
        return !this.isLoadingUpdate && this.competition.typeScore === "position" && !["finished", "inactive"].includes(this.competition?.status);
      },
    },
    methods: {
      sortedData(field) {
        this.$emit("sortData", field);
      },

      getFieldStyle(value) {
        if (value === "points" || value === "votes") {
          return "globalTable__text";
        } else {
          return "";
        }
      },

      handleRowClick(item, idx) {
        if (this.modal) {
          this.openModal(item, idx + 1);
        }
      },

      openModal(item, idx) {
        this.$emit("openModal", item, idx);
      },
      handleDragStart(event, index) {
        event.dataTransfer.setData("text/plain", index);
        event.target.classList.add("globalTable__dragging");
      },

      handleDragOver(event) {
        event.preventDefault();
        event.currentTarget.classList.add("globalTable__draggingHover");
      },
      handleDragLeave(event) {
        event.preventDefault();
        event.currentTarget.classList.remove("globalTable__draggingHover");
      },

      handleDragEnter(event) {
        event.preventDefault();
        this.indexInDrag = Array.from(event.currentTarget.parentNode.children).indexOf(event.currentTarget);
      },
      handleDrop(event) {
        event.preventDefault();
        const draggedIndex = event.dataTransfer.getData("text/plain");

        this.updatePositions(draggedIndex);
        document.querySelector(".globalTable__dragging").classList.remove("globalTable__dragging");
        document.querySelector(".globalTable__draggingHover").classList.remove("globalTable__draggingHover");
      },
      async updatePositions(initIdx) {
        if (!this.canDrag) {
          return;
        }
        this.isLoadingUpdate = true;
        const indexToDrag = this.indexInDrag;

        const { data } = await this.$axios.put(`participant/positions/${this.$route.params.id}`, { initIdx, indexToDrag });
        const { success } = data;

        if (success) {
          const element = this.data.splice(initIdx, 1)[0];

          this.data.splice(indexToDrag, 0, element);
          this.data.forEach((_, idx) => (this.data[idx].user.points = idx + 1));
        }
        this.isLoadingUpdate = false;
      },
    },
  };
</script>

<template>
  <table class="globalTable" aria-describedby="globalTable">
    <thead class="globalTable__thead">
      <tr class="globalTable__theadTr">
        <th @click="sortedData(header.key)" v-for="header in headers" :key="header.id" class="">
          <span class="globalTable__titles">{{ header.name }}</span>
          <iconic v-if="header.isSort" name="chevronDown" class="globalTable__filter" />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="globalTable__tbodyTr"
        v-for="(item, idx) in data"
        @click="handleRowClick(item, idx)"
        :draggable="canDrag"
        @dragstart="handleDragStart($event, idx)"
        @dragover="handleDragOver"
        @dragleave="handleDragLeave"
        @dragenter="handleDragEnter"
        @drop="handleDrop"
        :key="item.user._id">
        <td class="globalTable__file" v-for="header in headers" :key="header.id" :class="getFieldStyle(header.key)">
          <div v-if="header.key === 'name'" class="globalTable__user">
            <Avatar :user="item.user" class="globalTable__userImg" />
            {{ item.user.artisticName || item.user.user }}
          </div>
          <div v-else-if="header.key === 'votesPeople'" class="globalTable__user">
            <div v-for="(vote, index) in item.user.votesPeople.slice(0, 5).reverse()" :key="vote._id" :style="{ 'z-index': item.user.votesPeople.length - index }">
              <Avatar :user="vote.voter" class="globalTable__userImg" />
            </div>
            <div v-if="item.user.votesPeople.length > 5" class="globalTable__circle" :style="{ 'z-index': 1 }">+{{ item.user.votesPeople.length - 5 }}</div>
          </div>
          <div class="globalTable__contentItem" v-else>
            <span>{{ item.user[header.key] }}</span>
            <iconic class="globalTable__iconDrag" name="drag" v-if="header.key === 'points' && canDrag" />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss">
  .globalTable {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    th,
    td {
      padding: 8px;
      text-align: center;
    }
    &__contentItem {
      position: relative;
    }
    &__iconDrag {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      color: $chicago;
      cursor: grab;
    }
    &__theadTr {
      padding: 0.5rem 1rem;
      background: rgba(229, 229, 229, 1);
      border: 1px solid #e5e5e5;
    }
    &__titles {
      margin-right: 5px;
      font-size: 14px;
      text-transform: uppercase;
    }
    &__filter {
      font-size: 10px;
    }

    &__tbodyTr {
      padding: 0.5rem 1rem;
      border: 1px solid #e5e5e5;
      cursor: pointer;
    }
    &__dragging {
      background-color: #f0f0f0;
      opacity: 0.7;
    }
    &__draggingHover {
      border-bottom: 3px solid $primary-color;
      border-top: 3px solid $primary-color;
    }
    &__user {
      @include Flex(row, flex-start);
      gap: 10px;
    }
    &__userImg {
      width: 28px;
      height: 28px;
      border: 2px solid $primary-color;
    }
    &__text {
      color: $primary;
      font-weight: bold;
    }
    &__circle {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      color: #fff;
      background: $primary;
      font-size: 12px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }
  }
</style>
