<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  props: ["areas", "users"],

  computed: {
    ...mapGetters("competitions", ["getCompetition", "getUsersSelecetedView", "getInteractivePolicy", "getUsersSelectedInteractive"]),

    isAllChecked() {
      return this.users.every((user) => this.isCheckedUser(user));
    },

    isCheckedUser() {
      return function(userId) {
        return this.getInteractivePolicy ? this.getUsersSelectedInteractive.includes(userId) : this.getUsersSelecetedView.includes(userId);
      };
    },
  },
  methods: {
    ...mapMutations("competitions", ["setUsersSelecetedView", "setUsersSelectedInteractive"]),
    ...mapActions("competitions", ["updateUsersViewCompetition", "updateUsersInteractiveCompetition"]),

    async isCheckedAll(event) {
      event.preventDefault();
      const isChecked = event.srcElement.checked ? true : false;
      const contestId = this.getCompetition?._id;
      const action = isChecked ? "create" : "delete";
      const police = this.areas.length > 1 ? "all" : "area";
      const userId = this.areas.length >= 1 ? this.areas[0] : "";
      const policies = this.getInteractivePolicy ? "policiesInteration" : "policiesView";

      const usersData = { checkedAll: true, add: isChecked, users: this.users, areas: this.areas };

      if (this.getInteractivePolicy) {
        this.setUsersSelectedInteractive(usersData);
      } else {
        this.setUsersSelecetedView(usersData);
      }

      await this.updateUsersViewCompetition({ contestId, action, police, userId, policies });
      this.$toast.success("Actualizado");

      if (action === "delete") {
        this.setUsersSelectedInteractive(usersData);
        await this.updateUsersInteractiveCompetition({ contestId, action, police, userId });
        this.$toast.success("Actualizado");
      }
    },

    isCheckedUserClass(userId) {
      return this.getInteractivePolicy
        ? this.getUsersSelectedInteractive.some((user) => user._id === userId)
        : this.getUsersSelecetedView.some((ele) => ele._id === userId);
    },

    async checkedAction(user) {
      const contestId = this.getCompetition?._id;
      const action = "editUsers";
      const policies = this.getInteractivePolicy ? "policiesInteration" : "policiesView";

      if (this.getInteractivePolicy) {
        this.setUsersSelectedInteractive(user);
      } else {
        const isIncluded = this.getUsersSelectedInteractive.some((u) => u._id === user._id);

        if (isIncluded) {
          this.setUsersSelectedInteractive(user);
        }

        this.setUsersSelecetedView(user);
      }
      await this.updateUsersViewCompetition({ contestId, action, userId: user._id, police: "users", policies });
      this.$toast.success("Actualizado");
    },

    nameUser({ artisticName, user }) {
      return artisticName || user;
    },
  },
};
</script>
<template>
  <div class="competition__usersContainer custom__scroll">
    <label class="competition__listAreasName">
      <input @change="isCheckedAll" :checked="isAllChecked" type="checkbox" class="competition__checkArea" />
      <p class="competition__areaCheckName">todos</p>
    </label>

    <label
      v-for="user in users"
      :key="user._id"
      class="competition__usersSubContainer"
      :class="isCheckedUserClass(user._id) ? 'isCheckedUserClass' : ''"
    >
      <input @change="checkedAction(user)" type="checkbox" :checked="isCheckedUser(user)" class="competition__userCheck" />
      <Avatar class="competition__userImg" :user="user" />
      <span class="competition__userCheckName">{{ nameUser(user) }}</span>
    </label>
  </div>
</template>

<style lang="scss">
.competition {
  &__usersContainer {
    height: 430px;
    overflow-y: auto;
  }
  &__listAreasName {
    @include Flex(row, flex-start);
    min-width: 100%;
    height: auto;
    margin: 0.5rem 0;
    padding-top: 4px;
    gap: 0.5rem;
    cursor: pointer;
    :hover {
      background: #f6f6f6;
      border-radius: 0.5rem;
    }
  }
  &__listAreasNameContainer {
    @include Flex(row, space-between);
    width: 100%;
    height: 40px;
    padding: 0 8px;
    cursor: pointer;
    :hover {
      background: #f6f6f6;
      border-radius: 6px;
    }
  }

  &__checkArea,
  &__userCheck {
    width: 1.1rem;
    height: 1.1rem;
    appearance: none;
    background: #fff;
    position: relative;
    font-size: 120%;
    border: solid 1px rgba(221, 221, 221, 1);
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    &:checked {
      background-color: $lipstick;
      border-color: $lipstick;
      color: $white;
    }
    &:checked:before {
      content: "\2713";
      position: absolute;
      font-size: 80%;
      font-weight: bold;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
    &:hover {
      border-radius: 4px;
    }
  }

  &__userCheck {
    width: 1rem;
    height: 1rem;
  }
  &__areaCheckName {
    font-weight: bold;
    text-transform: capitalize;
  }
  &__userCheckName {
    text-transform: capitalize;
  }

  &__usersSubContainer {
    @include Flex(row, flex-start);
    padding: 0.5rem 0 0.5rem 1.2rem;
    width: 98%;
    gap: 10px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background: #f0f0f0;
    }
  }

  .isCheckedUserClass {
    margin: 0.2rem 0;
    background: rgba(189, 9, 9, 0.05);
  }
  &__userImg {
    width: 25px;
    height: 25px;
    border: 1.5px solid $primary;
  }
}
</style>
