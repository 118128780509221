<script>
import ResultVotesPoints from "./ResultVotesPoints.vue";
export default {
  props: {
    visible: Boolean,
    item: Object,
  },
  components: {
    ResultVotesPoints,
  },
  data() {
    return {
      modalVisible: false,
    };
  },
  computed: {
    itemName() {
      const { artisticName, user } = this.item.user;
      return artisticName || user;
    },
    itemCity() {
      return this.item.user.city;
    },
    itemOffice() {
      return this.item.user.office;
    },
    itemPoints() {
      return this.item.user.points;
    },
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<template>
  <div v-if="visible" class="competition__resultModal">
    <div @click="closeModal" class="competition__resultOverlay"></div>
    <div class="competition__modalContainer">
      <section class="competition__modalTitleContainer">
        <p class="competition__modalTitle">Detalle resultado individual</p>
        <iconic @click.native="closeModal" name="close" class="competition__modalTitleIcon" />
      </section>
      <section class="competition__modalBody">
        <div class="competition__modalBodyUser">
          <div class="competition__modalBodyUserContainer">
            <div class="competition__modalUser">
              <div class="competition__modalUserDescription">
                <Avatar :user="item.user" class="competition__modalUserPicture" />
                <span class="competition__modalUserTop">Top {{ item.idx }}</span>
              </div>
              <div class="competition__modalUserName">
                <span class="competition__modalName">{{ itemName }}</span>
                <span class="competition__modalCity">{{ itemCity }} - {{ itemOffice }}</span>
              </div>
            </div>
            <div class="competition__pointsContainer">
              <p class="competition__points">{{ itemPoints }} points</p>
            </div>
          </div>
        </div>
        <ResultVotesPoints :title="'Votantes'" :items="item.user.votesPeople" :votes="true" />
      </section>
    </div>
  </div>
</template>

<style lang="scss">
.competition {
  &__resultModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include Flex();
    z-index: 151;
    background: rgba(0, 0, 0, 0.4);
  }
  &__resultOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  &__modalContainer {
    min-height: 400px;
    width: 95%;
    max-width: 500px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    padding: 1rem;
  }
  &__modalTitleContainer {
    @include Flex(row, space-between);
  }
  &__modalTitle {
    color: $primary;
    margin-right: 10px;
    text-transform: uppercase;
    font-family: $sec_font;
    font-size: 18px;
  }
  &__modalTitleIcon {
    font-size: 10px;
    cursor: pointer;
  }
  &__modalBody {
    @include Flex(column, flex-start);
    padding: 1rem 0.1rem;
  }
  &__modalBodyUser {
    width: 100%;
    padding: 0 0.5rem;
    border-radius: 10px;
    background: rgba(248, 251, 253, 1);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }
  &__modalUser {
    @include Flex();
    gap: 10px;
  }
  &__modalUserDescription {
    position: relative;
    @include Flex();
  }
  &__modalUserPicture {
    width: 60px;
    height: 60px;
  }
  &__modalUserTop {
    position: absolute;
    bottom: -10px;
    padding: 2px 8px;
    font-size: 10px;
    font-weight: bold;
    background: $primary;
    color: #fff;
    border-radius: 4px;
    border: 1px solid $primary;
  }
  &__modalBodyUserContainer {
    @include Flex(row, space-between);
    padding: 1rem 0;
  }
  &__modalUserName {
    @include Flex(column, flex-start, flex-start);
  }
  &__modalName {
    line-height: 1;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
  }
  &__modalCity {
    font-size: 12px;
  }
  &__pointsContainer {
    padding: 0 0.4rem;
    border-radius: 24px;
    background: rgba(189, 9, 9, 0.13);
  }
  &__points {
    color: $primary;
    font-weight: bold;
    font-size: 12px;
  }

  @media (min-width: 769px) {
    &__modalName {
      font-size: 16px;
    }
    &__modalCity {
      font-size: 14px;
    }
    &__pointsContainer {
      padding: 0 0.8rem;
    }
    &__points {
      font-size: 14px;
    }
  }
}
</style>
