<script>
export default {
  props: ["user", "points"],
  computed: {
    nameUser() {
      const { artisticName, user } = this.user.user;
      return artisticName || user;
    },
    cityUser() {
      return this.user.user.city;
    },
    officeUser() {
      return this.user.user.office;
    },
  },
  methods: {
    openModal() {
      this.$emit("openModal", this.user);
    },
  },
};
</script>

<template>
  <div @click="openModal">
    <div class="competition__cardResultUser">
      <Avatar :user="user.user" class="competition__cardResultMobileImg" />
      <div class="">
        <p class="competition__cardResultMobileName">{{ nameUser }}</p>
        <div class="competition__cardResultMobileCountry">
          <span class="competition__cardResultMobileCountry">{{ cityUser }}</span>
          <span>{{ officeUser }}</span>
        </div>
      </div>
    </div>
    <span class="competition__cardResultMobileValue">{{ points }}</span>
  </div>
</template>

<style lang="scss">
.competition {
  @include Flex(column, flex-start);

  &__cardResultUser {
    @include Flex();
    gap: 10px;
  }
  &__cardResultMobileImg {
    width: 25px;
    height: 25px;
  }
  &__cardResultMobileName {
    font-size: 14px;
    line-height: 1;
    font-weight: bold;
    text-transform: capitalize;
  }
  &__cardResultMobileCountry {
    margin-right: 10px;
    font-size: 14px;
  }
  &__cardResultMobileValue {
    align-self: flex-start;
    color: $primary;
    font-weight: bold;
    font-size: 14px;
  }
}
</style>
