<script>
export default {
  props: {
    title: String,
    items: Array,
    votes: Boolean,
  },
  computed: {},
  methods: {
    nameUser(userSelected) {
      const { artisticName, user } = userSelected;
      return artisticName || user;
    },
  },
};
</script>
<template>
  <div class="competition__bodyInformation">
    <p class="competition__bodyInformationTitle">{{ title }}</p>
    <div class="competition__bodyInformationContainer custom__scroll">
      <div v-for="item in items" :key="item.id" class="competition__bodyInformationUser">
        <div class="competition__bodyInformationUserProfile">
          <Avatar :user="item.voter" class="competition__bodyInformationUserImg" />
          <span class="competition__bodyInformationUserName">{{ nameUser(item.voter) }}</span>
        </div>

        <div>
          <span class="competition__bodyInformationUserDate">{{ item.createdAt }}</span>
          <span class="competition__bodyInformationUserLine">|</span>
          <span class="competition__bodyInformationUserPoints"> {{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.competition {
  &__bodyInformation {
    align-self: flex-start;
    margin-top: 1rem;
    width: 100%;
  }
  &__bodyInformationTitle {
    font-weight: bold;
    line-height: 1.3rem;
  }
  &__bodyInformationContainer {
    width: 100%;
    max-height: 170px;
    overflow-y: auto;
  }
  &__bodyInformationUser {
    @include Flex(row, space-between);
    width: 100%;
    padding: 0.3rem 0.8rem;
    margin: 0.3rem 0;
    background: rgba(243, 243, 243, 1);
    border-radius: 6px;
  }
  &__bodyInformationUserProfile {
    @include Flex(row, flex-start);
    gap: 10px;
  }
  &__bodyInformationUserImg {
    width: 24px;
    height: 24px;
  }
  &__bodyInformationUserName {
    font-size: 15px;
  }
  &__bodyInformationUserDate {
    font-size: 10px;
  }
  &__bodyInformationUserLine {
    margin: 0 0.5rem;
    color: rgba(157, 157, 157, 1);
  }
  &__bodyInformationUserPoints {
    color: $primary;
    font-size: 13px;
    font-weight: bold;
  }
}
.custom__scroll {
  overflow-y: auto;
}
.custom__scroll::-webkit-scrollbar {
  width: 17px;
}

.custom__scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom__scroll::-webkit-scrollbar-thumb {
  background-color: rgb(151, 151, 151);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.custom__scroll::-webkit-scrollbar-thumb:hover {
  background-color: #4d4d4d;
}
</style>
