<script>
import UsersViewCompetition from "./UsersViewCompetition.vue";
import PolicyUsersByArea from "./PolicyUsersByArea.vue";
import PolicyUsersCompetitions from "./PolicyUsersCompetitions.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  props: ["users"],
  components: {
    UsersViewCompetition,
    PolicyUsersByArea,
    PolicyUsersCompetitions,
  },
  data() {
    return {
      searchUsers: "",
      selectedArea: "all",
      areasIds: [],
      menu: [
        {
          id: 1,
          show: false,
          title: "Usuarios que pueden visualizar",
          interactive: false,
        },
        {
          id: 2,
          show: false,
          title: "Usuarios que pueden comentar",
          interactive: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("user", ["getOrderUsersByArea"]),
    ...mapGetters("competitions", ["getUsersSelectInteractive"]),

    options() {
      if (this.selectedArea === "all") {
        this.areasIds = this.getOrderUsersByArea.map((area) => area.id);
        return this.users;
      } else {
        const areaFind = this.getOrderUsersByArea.find((area) => area.name === this.selectedArea);
        this.areasIds = [areaFind.id];
        return areaFind.users;
      }
    },
    nameIcon() {
      return function(item) {
        return item.show ? "chevronUp" : "chevronDown";
      };
    },
  },
  methods: {
    ...mapMutations("competitions", ["setInteractivePolicy"]),

    getFilteredUsers() {
      const search = this.searchUsers.toLowerCase();

      let filteredUsers;
      if (this.menu[0].show) {
        this.setInteractivePolicy(false);
        filteredUsers = this.options;
      } else {
        this.setInteractivePolicy(true);
        filteredUsers = this.options.filter((user) => this.getUsersSelectInteractive.map((ele) => ele._id).includes(user._id));
      }

      filteredUsers = filteredUsers.filter((user) => {
        const { user: userName, city, artisticName, office } = user;
        const includesUser = this.verifyIncludeInDate(userName, search);
        const includesCity = this.verifyIncludeInDate(city, search);
        const includesArtisticName = this.verifyIncludeInDate(artisticName, search);
        const includesOffice = this.verifyIncludeInDate(office, search);
        
        return includesUser || includesCity || includesArtisticName || includesOffice;
      });

      return filteredUsers;
    },

    verifyIncludeInDate(dateIn, compare) {
      const formattedDate = this.cleanString(dateIn || "");
      const formattedCompare = this.cleanString(compare);
      return formattedDate.includes(formattedCompare);
    },

    cleanString(str) {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    },

    changeMenu(item) {
      this.menu = this.menu.map((ele) => ({
        ...ele,
        show: ele.id === item.id ? !ele.show : false,
      }));
    },
  },
};
</script>

<template>
  <section class="competition__policyView">
    <div class="competition__policyViewFilters">
      <label class="competition__searchUser">
        <iconic name="my_search" class="competition__searchIcon" />
        <input v-model="searchUsers" type="text" placeholder="Buscar usuario" class="competition__searchInput" />
      </label>

      <div class="competition__policyViewSelect">
        <iconic class="competition__policyViewSelect-iconic" name="filter"></iconic>
        <select name="miSelect" v-model="selectedArea" class="competition__policyViewSelectFilters">
          <option value="all">Todos</option>
          <option v-for="area in getOrderUsersByArea" :key="area._id" :value="area.name"> {{ area.name }}</option>
        </select>
      </div>
    </div>
    <ul class="competition__policyViewMenus">
      <li v-for="item in menu" :key="item.id">
        <div @click="changeMenu(item)" class="competition__policyViewMenuTitle">
          <p class="competition__policyViewTitle">{{ item.title }}</p>
          <iconic class="competition__policyViewSelect-iconic" :name="nameIcon(item)" />
        </div>
        <PolicyUsersCompetitions
          v-if="item.show"
          :users="getFilteredUsers()"
          :areas="areasIds"
          :interactive="item.interactive"
          class="competition__policyViewSections"
        />
      </li>
    </ul>
  </section>
</template>

<style lang="scss">
.competition {
  &__policyViewMenus {
    margin: 1rem 0;
  }
  &__policyViewMenuTitle {
    @include Flex(row, space-between);
    max-width: 100%;
    margin: 0.4rem 0;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    background: #fafafa;
    cursor: pointer;
    &:hover {
      background: rgba(109, 111, 128, 0.08);
    }
  }
  &__policyViewTitle {
    font-weight: bold;
    line-height: 19px;
  }
  &__policyViewFilters {
    display: grid;
    align-items: center;
    gap: 10px;
    margin: 13px 0;
    margin-bottom: 5px;
    padding: 0 0.5rem;
  }
  &__policyViewSections {
    height: 95%;
  }
  &__searchUser {
    @include Flex();
    padding: 0.4rem 0.8rem;
    gap: 5px;
    width: 100%;
    border-radius: 22px;
    border: 1px solid rgba(92, 89, 89, 0.25);
  }
  &__policyViewSelect {
    @include Flex(row, flex-start);
    gap: 10px;
    width: 100%;
    border-radius: 22px;
    padding: 0.45rem 0.8rem;
    border: 1px solid rgba(92, 89, 89, 0.25);
    cursor: pointer;
    &-iconic {
      width: 13px;
    }
  }
  &__policyViewSelectFilters {
    width: 100%;
    border: none;
    padding-left: 10px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  @media (min-width: 769px) {
    &__policyViewSections {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    &__policyViewSectionSelect {
      border-right: 1px solid rgba(221, 221, 221, 1);
    }
    &__policyViewFilters {
      grid-template-columns: repeat(2, 1fr);
    }
    &__policyViewSelect {
      width: 100%;
    }
  }
}
</style>
